/*
 * Configuration
 */

export const COMAP_URL = '#';

export const GA_ID = '';

export const HEAP_ID = '1803472052';

export const FORMSPREE_URL = 'https://formspree.io/f/xyyvjkzy';

export const MOBILE_BREAKPOINT = 1150;

export const MENU = {
    en: [
        { text: 'Welcome', href: '#welcome' },
        { text: 'Resources', href: '#resources' },
        { text: 'Stay Informed', href: '#share' },
        { text: '', href: '' },
    ],
    Hmong: [
        { text: 'Zoo siab txais tos', href: '#welcome' },
        { text: 'Cov peev txheej', href: '#resources' },
        { text: 'Caum kom paub txog tus txheej xwm', href: '#share' },
        { text: '', href: '' },
    ],
    es: [
        { text: 'Bienvenidos', href: '#welcome' },
        { text: 'Recursos', href: '#resources' },
        { text: 'Mantente informado', href: '#share' },
        { text: '', href: '' },
    ],
};
