const firebaseConfig = {
    apiKey: "AIzaSyBQd8XzfjAB7T_vBTt1DCHq8ObTAklIcc8",
    authDomain: "lake-manona-website.firebaseapp.com",
    projectId: "lake-manona-website",
    storageBucket: "lake-manona-website.appspot.com",
    messagingSenderId: "860001755891",
    appId: "1:860001755891:web:6f3c6e81e5f40165314d86"
};

export default firebaseConfig;
