import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';
import { COMAP_URL } from '../../config';
import ContactForm from '../ContactForm/ContactForm';
import { Callout, Pin } from '../MapIcons/MapIcons';
import homeIcon from '../../assets/home.svg';


export default observer(function Contact({ section }) {
    const { bindTo } = useHenshu();

    return (
        <Section index={2} name="Contact">
           <div className="row">
                <ContactForm/>
                <div className="engagement-content"/>
            </div>
        </Section>
    );
});
