import { useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';

import Section from '../Section';

export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <Section name="Welcome" index={0} anchor={false}>
            <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 110, left: 0, behavior: 'smooth' })}
            >
                <henshu.p {...bindTo('mainscroll.prompt')} />
                <FiChevronDown />
            </footer>
        </Section>
    );
});
