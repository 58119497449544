import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { Anchor } from '@strategies/react-anchors';

import { MENU } from '../config';


export default observer(function Section({ anchor = true, children, index, name }) {
    const { bindTo } = useHenshu();
    const { lang } = useHenshuApp();
    const id = typeof index === 'number' ? MENU[lang][index].href.replace('#', '') : '';

    return (
        <div className={`Section ${name}`}>
            {anchor ? <Anchor id={id} /> : <div className="Anchor" id={id} />}

            <div className="content">
                {lang === 'en' && (
                    <henshu.h3 {...bindTo(`${name}.subtitle`)} />
                )}
                <henshu.h2 {...bindTo(`${name}.title`)} />

                {children}
            </div>
        </div>
    );
});
