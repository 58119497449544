import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHenshuApp } from '@strategies/henshu-app';
import { henshu, useHenshu } from '@strategies/henshu';
import { FiImage } from 'react-icons/fi';
import { Modal, Title, Body } from '@strategies/ui';

import Section from '../Section';

import rightArrowIcon from '../../assets/arrow-icon-blue.svg';


const LINKS = [
    'https://media.cityofmadison.com/mediasite/Showcase/madison-city-channel/Presentation/e9c25e18ea934050a282523eb47c51071d',
    'https://www.sasaki.com/voices/sasaki-shortlisted-in-competition-to-reimagine-madison-wi-waterfront/',
    'https://vimeo.com/768677489',
    'https://media.cityofmadison.com/Mediasite/Play/08a98b0960ca4f9596849f942ea34b5e1d?playFrom=7276000',
    'https://firebasestorage.googleapis.com/v0/b/lake-manona-website.appspot.com/o/Lake%20Monona%20Midterm_Sasaki.pdf?alt=media&token=bffa1bac-8369-4292-a7d0-3be037ed41d9',
    'https://firebasestorage.googleapis.com/v0/b/lake-manona-website.appspot.com/o/SASAKI_Lake%20Monona%20Waterfront%20Design%20Challenge_Public%20Meeting%20Boards.pdf?alt=media&token=0c5a9326-aeda-4b8f-b137-713bf098fed0',
    'https://vimeo.com/793115426',
    'https://www.surveymonkey.com/r/WHBCQ23',
    'https://www.sasaki.com/voices/voices-of-the-lake-imagining-the-future-of-lake-mononas-waterfront/',
    'https://media.cityofmadison.com/Mediasite/Play/172c34b2204247bbab056f68043ff4c61d',
].reverse();


export default observer(function Resources() {
    const { bindTo } = useHenshu();
    const { persist, isEditing } = useHenshuApp();
    const { upload } = persist;
    const [modal, setModal] = useState(null);

    return (
        <Section index={1} name="Resources">
            <ul>
                <henshu.each {...bindTo('resources')}>
                    {(bindToEach, i) => (
                        <li
                            key={i}
                            onClick={modal !== null || isEditing ? () => {} : () => window.open(LINKS[i], '_blank')}
                            style={{ backgroundImage: `url(${bindToEach('image').get()})`}}
                        >
                            {isEditing && (
                                <henshu.img
                                    get={() => bindToEach('image').get()}
                                    set={async buf => bindToEach('image').set(buf ? await upload(buf) : '')}
                                />
                            )}

                            <div className="resource-content">
                                <div className="icon-wrapper">
                                    <FiImage />
                                    <img src={rightArrowIcon} aria-hidden="true"/>
                                </div>

                                <henshu.h4 {...bindToEach('title')} />
                                <henshu.p {...bindToEach('subtitle')} />
                            </div>

                            <Modal active={modal === i} onClose={() => setModal(null)}>
                                <Title><henshu.h3 {...bindToEach('title')} /></Title>
                                <Body>
                                    <henshu.richtext {...bindToEach('modal.body')} />
                                </Body>
                            </Modal>
                        </li>
                    )}
                </henshu.each>
            </ul>
        </Section>
    );
});
