/*
 * Seeds
 *
 * Use this file to populate an empty Firestore or reset the content
 * of the Firestore.
 */

import en from './assets/seeds/en.json';
import es from './assets/seeds/es.json';
import Hmong from './assets/seeds/Hmong.json';

const updates = {
    "resources":[
        {
          "image": "https://firebasestorage.googleapis.com/v0/b/lake-manona-website.appspot.com/o/uploads%2F3f1f7a3de79929dd616470ad7eed7278?alt=media&token=476705ef-24cd-4812-8770-a36ad4da6319",
          "title": "Sasaki Shortlisted in Competition to Reimagine Madison, WI Waterfront",
          "subtitle": "Open Here"
        },
        {
          "subtitle": "Open Here",
          "title": "Check out our kick-off presentation",
          "image": "https://firebasestorage.googleapis.com/v0/b/lake-manona-website.appspot.com/o/uploads%2Fc1bf84d93f82ecbf0ef03a69364f7e2e?alt=media&token=d4aaccb8-f8aa-4dce-ae59-a7f65e1897c8"
        }
    ],
}

const seeds = {
    en,
    es: { ...es, ...updates},
    Hmong: { ...Hmong, ...updates },
};


export default seeds;
