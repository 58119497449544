import React from 'react';
import ReactDOM from 'react-dom';
import { HenshuApp } from '@strategies/henshu-app';

import App from './App';
import seeds from './seeds';
import firebaseConfig from './firebaseConfig';
import { GA_ID, HEAP_ID } from './config';


if (process.env.REACT_APP_HENSHU_ENV === 'production') {
    if (GA_ID !== '' && window.gtag) {
        window.gtag('config', GA_ID);
    }
    if (HEAP_ID !== '' && !window.heap.loaded) {
        window.heap.load(HEAP_ID);
    }
}

ReactDOM.render(
    <React.StrictMode>
        <HenshuApp config={firebaseConfig} seeds={seeds} langs={['en', 'es', 'Hmong']}>
            <App />
        </HenshuApp>
    </React.StrictMode>,
    document.getElementById('root')
);
