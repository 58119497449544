import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';


export const Callout = observer(({ term }) => {
    const { bindTo } = useHenshu();

    return (
        <henshu.div className="Callout" {...bindTo(term)} />
    );
});

export const Pin = observer(({ alt, className, icon, term }) => {
    const { bindTo } = useHenshu();

    return (
        <div className={`Pin ${className ? className : ''}`}>
            <img src={icon} alt={alt} />
            <henshu.span {...bindTo(term)} />
        </div>
    )
});
