import { useState } from 'react';
import { observer } from 'mobx-react';
import { henshu, useHenshu } from '@strategies/henshu';
import { useHenshuApp } from '@strategies/henshu-app';
import { WatchAnchors } from '@strategies/react-anchors';
import ReactMobilized from '@strategies/react-mobilized';

import './App.scss';

import LanguageSelect from './components/LanguageSelect/LanguageSelect';
import { MENU, MOBILE_BREAKPOINT } from './config';
import Contact from './components/Contact/Contact';
import Resources from './components/Resources/Resources';
import Welcome from './components/Welcome/Welcome';
import ArrowIcon from './assets/arrow-icon-blue.svg';
import Logo from './assets/Logo.png';
import Sasaki from './assets/sasaki.png';


export default observer(function App() {
    const [section, setSection] = useState(0);
    const { bindTo } = useHenshu();
    const { lang } = useHenshuApp();
    console.log(MENU[lang]);

    return (
        <div className="App">
            <ReactMobilized
                anchors={MENU[lang]}
                customAnchor={anchor => {
                    if (anchor.href === '' && anchor.text === '') {
                        return <LanguageSelect />
                    }
                    else {
                        return <a href={anchor.href}>{anchor.text}</a>
                    }
                }}
                logo={<>
                    <img src={Logo} alt={bindTo('title').get()} />
                    <henshu.h1 {...bindTo('title')} />
                </>}
                breakpoint={MOBILE_BREAKPOINT}
                section={section + 1}
                setCurrentSection={n => setSection(n - 1)}
            >
                <WatchAnchors
                    onTop={anchor => setSection(anchor.index)}
                    onOffscreen={anchor => !(anchor.top < 0) && setSection(anchor.index - 1)}
                >
                    <Welcome />
                    <Resources />
                    <Contact section={section} />
                </WatchAnchors>

                <footer>
                    <div className="logo sasaki">
                        <a href="https://www.sasaki.com" rel="noreferrer noopener" target="_blank">
                            <img src={Sasaki} alt="Sasaki" />
                        </a>
                    </div>

                    <div className="logo">
                        <img src={Logo} alt={bindTo('title').get()} />
                        <henshu.p {...bindTo('title')} />
                    </div>

                    <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                        <img src={ArrowIcon} alt="" />
                    </button>
                </footer>
            </ReactMobilized>
        </div>
    );
});
